import axios from "axios";
import {
  backend_url,
  backend_url8001,
  backend_url5000,
  frontend_url,
  dataVizard,
  Optilogistics_url,
  Content_url,
  Content_url2,
  Content_url3,
} from "./config";
// import textToImage from "./config"
// import { Header } from "./commonVariable";

// axios.get(url, Header);
// axios.post(url,DATA, Header);
// axios.put(url, DATA, Header);
// axios.delete(url, { data: DATA, headers: { "Authorization": `Bearer ${token}` } });

let token = JSON.parse(localStorage.getItem("genAi"));

function commonHeader() {
  let token = JSON.parse(localStorage.getItem("genAi"));
  let Header = { headers: { Authorization: `Bearer ${token}` } };
  return Header;
}

export async function loginAPI(data) {
  try {
    const res = await axios.post(`${backend_url}/login/login`, data);
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in loginApi" };
  }
}

export async function getActivateAPI(userId) {
  try {
    const res = await axios.get(`${backend_url}/login/activateuser/${userId}`);
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in loginApi" };
  }
}
export async function ifschedule(session) {
  try {
    const res = await axios.get(
      `${backend_url}/datadive/checkSessionForSchedule/${session}`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in loginApi" };
  }
}
export async function filecount(data) {
  try {
    const res = await axios.post(
      `${backend_url}/datadive/checkContineousluForfiles`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in createJobAPI" };
  }
}
export async function fileschedule(data) {
  try {
    const res = await axios.post(
      `${backend_url}/datadive/start_file_watcher_api`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in createJobAPI" };
  }
}
export async function filescheduleTime(data) {
  try {
    const res = await axios.post(
      `${backend_url}/datadive/ScheduleTime`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in createJobAPI" };
  }
}
export async function fileschedulemsg(data) {
  try {
    const res = await axios.post(
      `${backend_url}/datadive/get_time_name`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in createJobAPI" };
  }
}
export async function fileschedulehistory(data) {
  try {
    const res = await axios.post(
      `${backend_url}/datadive/getHistoryWithFiles`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in createJobAPI" };
  }
}
export async function stopschedule(DATA) {
  try {
    const res = await axios.delete(`${backend_url}/datadive/stop_schedular`, {
      data: DATA,
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in deleteSessionAPI" };
  }
}

export async function putActivateAPI(userId, data) {
  try {
    const res = await axios.put(
      `${backend_url}/login/activateuser/${userId}`,
      data
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in loginApi" };
  }
}

export async function forgotPasswordAPI(data) {
  try {
    const res = await axios.post(`${backend_url}/login/forgot_password`, data);
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in loginApi" };
  }
}

export async function getDataByUserIdAPI(userId) {
  try {
    const res = await axios.get(
      `${backend_url}/login/change_password/${userId}`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in loginApi" };
  }
}

export async function changePasswordAPI(userId, data) {
  try {
    const res = await axios.put(
      `${backend_url}/login/change_password/${userId}`,
      data
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in loginApi" };
  }
}

export async function getActorRoleAPI() {
  try {
    const res = await axios.get(
      `${backend_url}/chat/prompt_templates`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in getActorRoleAPI" };
  }
}

export async function postCreateRoleAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/prompt_templates`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in postCreateRole" };
  }
}

export async function putEditRoleAPI(data) {
  try {
    const res = await axios.put(
      `${backend_url}/chat/prompt_templates`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in putEditRole" };
  }
}

export async function getSessionsAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/get_sessions`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in getSessions" };
  }
}

export async function postSessionAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/new_chat`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in postSession" };
  }
}

export async function getChatAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/get_chat`,
      data,
      commonHeader()
    );
    // var i = 0
    // for(i; i < res.data['data'].length; i ++){

    // }

    // var arr = JSON.parse(res.data);
    // var a = [];
    // var i = 0;
    // for (i; i < res.data["data"].length; i++) {
    //   a.push(res.data["data"][i]["genai_bot"][0]);
    //   // console.log("serdfgwesrgdfewsr---", res.data['data'][i]['genai_bot'][0])
    // }

    // console.log("Hay darling i am here------", a);
    // console.log("fdkjbghfbivlkdfb..................",res.data);
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in getChat" };
  }
}

export async function postautoGenerateAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/autogenerate`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in postautoGenerateAPI" };
  }
}

export async function putautoGenerateAPI(data) {
  try {
    const res = await axios.put(
      `${backend_url}/chat/autogenerate`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in putautoGenerateAPI" };
  }
}

export async function postChatAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/conversational_ai`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in getChat" };
  }
}

export async function dataUrlSaveAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/data_url_save`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in getChat" };
  }
}

export async function postDataChatAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/data_url`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in getChat" };
  }
}

export async function getAllCredentailsAPI(data) {
  try {
    const res = await axios.get(
      `${backend_url}/chat/get_all_credentials`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in getChat" };
  }
}

export async function s3ValidateAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/validate`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in getChat" };
  }
}

export async function saveS3API(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/save_s3`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in getChat" };
  }
}

export async function postS3ChatAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/s3_qanda`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in getChat" };
  }
}

export async function saveConfluenceAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/save_confluence`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in saveConfluenceAPI" };
  }
}

export async function confluenceMessageAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/confluence_message`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in saveConfluenceAPI" };
  }
}

export async function getDataUrlAPI(data) {
  try {
    const res = await axios.get(
      `${backend_url}/chat/data_url_save`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in saveConfluenceAPI" };
  }
}

export async function putDataUrlAPI(data) {
  try {
    const res = await axios.put(
      `${backend_url}/chat/data_url_save`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in saveConfluenceAPI" };
  }
}

export async function putS3API(data) {
  try {
    const res = await axios.put(
      `${backend_url}/chat/save_s3`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in saveConfluenceAPI" };
  }
}

export async function putConfluenceAPI(data) {
  try {
    const res = await axios.put(
      `${backend_url}/chat/save_confluence`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in saveConfluenceAPI" };
  }
}

export async function deleteActorRoleAPI(DATA) {
  try {
    const res = await axios.delete(`${backend_url}/chat/prompt_templates`, {
      data: DATA,
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in deleteActorRoleAPI" };
  }
}

export async function deleteSessionAPI(DATA) {
  try {
    const res = await axios.delete(`${backend_url}/chat/new_chat`, {
      data: DATA,
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in deleteSessionAPI" };
  }
}

export async function getAllJobAPI() {
  try {
    const res = await axios.get(
      `${backend_url}/finetuning/finetuningnewtab`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in saveConfluenceAPI" };
  }
}

export async function getJobDetailsAPI() {
  try {
    const res = await axios.get(
      `${backend_url}/chat/get_job_input_details`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in saveConfluenceAPI" };
  }
}

export async function createJobAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/finetuning/finetuningnewtab`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in createJobAPI" };
  }
}

export async function getRunsAPI(job_name) {
  try {
    const res = await axios.get(
      `${backend_url}/finetuning/get_job_runs/${job_name} `,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in saveConfluenceAPI" };
  }
}

export async function createRunAPI(jobName, data) {
  try {
    const res = await axios.post(
      `${backend_url}/finetuning/create_run/${jobName}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in createJobAPI" };
  }
}

export async function eachRunDataAPI(job_name, run_name) {
  try {
    const res = await axios.get(
      `${backend_url}/finetuning/get_artifacts/${job_name}/${run_name} `,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in saveConfluenceAPI" };
  }
}

export async function getRunDataAPI(job_name) {
  try {
    const res = await axios.get(
      `${backend_url}/finetuning/create_run/${job_name}`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in saveConfluenceAPI" };
  }
}

export async function getAllModelAPI() {
  try {
    const res = await axios.get(
      `${backend_url}/optimization/create_new_job`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in saveConfluenceAPI" };
  }
}

export async function createJobOPTAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/optimization/create_new_job`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in createJobOPTAPI" };
  }
}

export async function getAllJobOPTAPI() {
  try {
    const res = await axios.get(
      `${backend_url}/optimization/get_all_jobs`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in getAllJobOPTAPI" };
  }
}

export async function getJobNameOptAPI(jobName) {
  try {
    const res = await axios.post(
      `${backend_url}/optimization/get_artifacts`,
      {
        job_name: jobName,
      },
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in createJobOPTAPI" };
  }
}

export async function s3ChatValidateAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/validate`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in s3ChatValidateAPI" };
  }
}

export async function objectsS3API(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/s3_objects`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in objectsS3API" };
  }
}

export async function getConfDataAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/confluence_objects`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in confluence_objects" };
  }
}

export async function storeCredAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/save_session`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in confluence_objects" };
  }
}

export async function checkSessionAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/check_session`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in checkSession" };
  }
}

export async function getDataDiveChatAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/data_dive`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in getDataDiveChat" };
  }
}

export async function deleteJobOneAPI(DATA) {
  try {
    const res = await axios.delete(`${backend_url}/finetuning/delete_job`, {
      data: DATA,
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in deleteJobOne" };
  }
}

export async function deleteRunAllAPI(DATA) {
  try {
    const res = await axios.delete(`${backend_url}/finetuning/delete_run`, {
      data: DATA,
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in deleteRunAll" };
  }
}

export async function hubPlusDataAPI() {
  try {
    const res = await axios.get(
      `${backend_url}/finetuning/hubplus`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in hubPlusData" };
  }
}

export async function getSharePointSitesAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/get_sit`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in getSharePointSitesAPI" };
  }
}

export async function sharePointDataBaseAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/get_drive`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in getSharePointSitesAPI" };
  }
}

export async function sharepointFilesAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/getting_files`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in sharepointFilesAPI" };
  }
}

export async function postModelAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/finetuning/deploy_request`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in postModelAPI" };
  }
}

export async function textToImageAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/optimization/text_to_image`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in textToImageGeneration" };
  }
}

export async function textToMultipleImageAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/optimization/text_to_multiple_image`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in textToImageGeneration" };
  }
}

export async function create_business(data) {
  try {
    const res = await axios.post(
      `${backend_url}/genai_settings/business_unit`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);

    return { sucess: false, message: "error in createbusiness" };
  }
}
export async function business_users() {
  try {
    const res = await axios.get(
      `${backend_url}/genai_settings/business_unit`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "error in UserManage" };
  }
}

export async function uploadAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/FileUploadView`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in uploadAPI" };
  }
}
export async function business_list(data) {
  try {
    const res = await axios.post(
      `${backend_url}/genai_settings/business_unit_all_list`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);

    return { sucess: false, message: "error in createbusiness" };
  }
}

export async function business_details() {
  try {
    const res = await axios.get(
      `${backend_url}/genai_settings/business_unit_all_list`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "error in UserManage" };
  }
}

export async function create_role(newData) {
  try {
    const res = await axios.post(
      `${backend_url}/genai_settings/role_managements`,
      newData,

      commonHeader()
    );

    return res.data;
  } catch (e) {
    console.log("error---", e);

    return { sucess: false, message: "error in createrole" };
  }
}
export async function list_roleUsers() {
  try {
    const res = await axios.get(
      `${backend_url}/genai_settings/role_managements`,

      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);

    return { sucess: false, message: "error in listroleusers" };
  }
}

export async function edit_user(data) {
  try {
    const res = await axios.put(
      `${backend_url}/genai_settings/list_users`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);

    return { sucess: false, message: "error in editeuser" };
  }
}

export async function create_user(data) {
  try {
    const res = await axios.post(
      `${backend_url}/login/register`,
      data,

      commonHeader()
    );

    return res.data;
  } catch (e) {
    console.log("error---", e);

    return { sucess: false, message: "error in createuser" };
  }
}
export async function delete_user(body) {
  try {
    const res = await axios.delete(`${backend_url}/genai_settings/list_users`, {
      data: body,
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log("---", body);
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "error in delete user" };
  }
}

export async function list_users() {
  try {
    const res = await axios.get(
      `${backend_url}/genai_settings/list_users`,
      commonHeader()
    );

    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "error in UserManage" };
  }
}

export async function getAllS3Objects(data) {
  try {
    const res = await axios.post(
      `${backend_url}/datadive/list_all_s3_objects`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in getAllS3Objects" };
  }
}

export async function chat_LLM_API(data) {
  try {
    const res = await axios.post(
      `${backend_url}/embeddings/query_document_with_llm`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in query document with llm" };
  }
}

export async function generateDataAndEmbed(data) {
  try {
    const res = await axios.post(
      `${backend_url}/embeddings/process_data_and_generate_embeddings`,
      data,
      commonHeader()
    );
    return res;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in getAllS3Objects" };
  }
}

export async function datapointerAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/datadive/data_source_data_pointer`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in getAllS3Objects" };
  }
}

export async function shartPointAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/datadive/sharepoint_retrive_data`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in shartPoint" };
  }
}

export async function checkCredSessionAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/datadive/check_embeddings`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in shartPoint" };
  }
}

export async function goalsAPI() {
  try {
    const res = await axios.post(`${backend_url8001}/goal`, "", commonHeader());
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in goalsAPI" };
  }
}

export async function getTableAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url8001}/get_data`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in getTableAPI" };
  }
}

export async function getGraphAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url8001}/visualize`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in getGraphAPI" };
  }
}

export async function recommandGraphAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url8001}/visualize/recommend`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in recommandGraphAPI" };
  }
}

export async function databaseConnectAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url8001}/connect`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in databaseConnectAPI" };
  }
}

export async function generateCodeTerraAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/terraform/generate`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Get Session DataDive" };
  }
}

export async function validateCodeTerraAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/terraform/validation`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Get Session DataDive" };
  }
}

export async function deployeCodeTerraAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/terraform/deploy`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Deployment" };
  }
}

export async function destryCodeTerraAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/terraform/destroy`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Destroying" };
  }
}

export async function createSessionDataDiveAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/datadive/datadive_newchat`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Get Session DataDive" };
  }
}

export async function getSessionDataDiveAPI() {
  try {
    const res = await axios.get(
      `${backend_url}/datadive/datadive_newchat`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Get Session DataDive" };
  }
}

export async function postSessionDataDiveAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/datadive/datadive_newchat`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Get Session DataDive" };
  }
}

export async function deleteSessionDataDiveAPI(DATA) {
  try {
    const res = await axios.delete(`${backend_url}/datadive/datadive_newchat`, {
      data: DATA,
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in Delete Session DataDive" };
  }
}

export async function deleteFlowSessionDataDiveAPI(DATA) {
  try {
    const res = await axios.delete(
      `${backend_url}/embeddings/delete_datadive_session`,
      {
        data: DATA,
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in Delete Session DataDive" };
  }
}

export async function getNodesAndEdgesAPI(data) {
  try {
    const res = await axios.get(
      `${backend_url}/datadive/get_session_data/${data}`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in get Nodes and Edges" };
  }
}
export async function getTeraformFilesAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/terraform/getFiles`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in get Nodes and Edges" };
  }
}

export async function updateTeraFileCount(data) {
  try {
    const res = await axios.put(
      `${backend_url}/terraform/getFiles`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in putEditRole" };
  }
}

export async function getTeraFIleContentAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/terraform/getFileContent`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in get Nodes and Edges" };
  }
}

export async function getTeraformProjectAPI() {
  try {
    const res = await axios.get(
      `${backend_url}/terraform/Projects`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in get Nodes and Edges" };
  }
}

export async function createTeraformProjectAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/terraform/Projects`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in pgvector" };
  }
}

export async function deleteTeraformProjectAPI(DATA) {
  try {
    const res = await axios.delete(`${backend_url}/terraform/Projects`, {
      data: DATA,
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in Delete Session DataDive" };
  }
}

export async function postNodesAndEdgesAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/datadive/react_nodes`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in post Nodes and Edges" };
  }
}

export async function pgvectorAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/datadive/pgvector_credentials`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in pgvector" };
  }
}

// Monitoring Activity Data
export async function getActivityJobAPI() {
  try {
    const res = await axios.get(`${backend_url}/chat/activity`, commonHeader());
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in get Nodes and Edges" };
  }
}
// Monitoring Visualization Data
export async function getGraphsAPI() {
  try {
    const res = await axios.get(
      `${backend_url}/chat/visualization`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in get Nodes and Edges" };
  }
}

// graphs based on different modal
export async function modelGraphsAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/chat/get_model_data`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in pgvector" };
  }
}

export async function regenerateMessageAPI(data) {
  try {
    const res = await axios.put(
      `${backend_url}/chat/regeneration`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in putEditRole" };
  }
}

// Data Vizard

export async function postDataSourceAPI(data) {
  try {
    const res = await axios.post(
      `${dataVizard}/datasource`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Data Source" };
  }
}

export async function allDataSourceAPI() {
  try {
    const res = await axios.get(`${dataVizard}/datasources`, commonHeader());
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Data Source" };
  }
}

export async function deleteDataSourceAPI(DATA) {
  try {
    const res = await axios.delete(`${dataVizard}/datasource/${DATA}`, {
      data: DATA,
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in deleteSessionAPI" };
  }
}

export async function eachDataSourceDataAPI(id) {
  try {
    const res = await axios.get(
      `${dataVizard}/datasource-all-data/${id}`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Data Source" };
  }
}

export async function updatingDataSourceAPI(id, data) {
  try {
    const res = await axios.put(
      `${dataVizard}/datasource-all-data/${id}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in updating DataSource API" };
  }
}

export async function createProjectAPI(data) {
  try {
    const res = await axios.post(`${dataVizard}/project`, data, commonHeader());
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Create Project" };
  }
}

export async function allProjectNameAPI() {
  try {
    const res = await axios.get(`${dataVizard}/projects`, commonHeader());
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Data Source" };
  }
}

export async function deleteProjectNameDataVizardAPI(DATA) {
  try {
    const res = await axios.delete(`${dataVizard}/project/${DATA}`, {
      data: DATA,
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in deleteSessionAPI" };
  }
}

export async function allQuestionsAPI(id) {
  try {
    const res = await axios.get(
      `${dataVizard}/questions/${id}`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Data Source" };
  }
}

// DataVizard Projects

export async function projectPostAPI(data) {
  try {
    const res = await axios.post(`${dataVizard}/project`, data, commonHeader());
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Create Project" };
  }
}

export async function getSpecificProjectAPI(id) {
  try {
    const res = await axios.get(`${dataVizard}/project/${id}`, commonHeader());
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Data Source" };
  }
}

export async function postQuestionAPI(datasource_id, data) {
  try {
    const res = await axios.post(
      `${dataVizard}/generate-sql/${datasource_id}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Create Project" };
  }
}

// export async function postQueryAPI(data) {
//   try {
//     const res = await axios.post(
//       `${dataVizard}/execute-sql/${datasource_id}`,
//       data,
//       commonHeader()
//     );
//     return res.data;
//   } catch (e) {
//     console.log("error---", e);
//     return { sucess: false, message: "Error in Create Project" };
//   }
// }

// export async function postTogetChartAPI(data) {
//   try {
//     const res = await axios.post(
//       `${dataVizard}/generate-visualization/${datasource_id}`,
//       data,
//       commonHeader()
//     );
//     return res.data;
//   } catch (e) {
//     console.log("error---", e);
//     return { sucess: false, message: "Error in Create Project" };
//   }
// }

export async function getSpecificProjectChartAPI(project_id) {
  try {
    const res = await axios.get(
      `${dataVizard}/chart-all/${project_id}`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Data Source" };
  }
}

export async function createChartAPI(data) {
  try {
    const res = await axios.post(`${dataVizard}/chart`, data, commonHeader());
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in pgvector" };
  }
}

export async function updateChartAPI(id, data) {
  try {
    const res = await axios.put(
      `${dataVizard}/chart/${id}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in updateChartAPI" };
  }
}

// plsql/
export async function createPlsqlProjectAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/appstore/CreateGet`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in pgvector" };
  }
}

export async function sqlfileUploadAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/appstore/FileUploadView`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in pgvector" };
  }
}

export async function getjavafilesAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/appstore/getFiles`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in pgvector" };
  }
}

export async function convertJAVAAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/appstore/ConvertToJava`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in pgvector" };
  }
}

export async function getJavaFileDataAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/appstore/getFileContent`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in pgvector" };
  }
}

export async function postjavacodeAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/appstore/saveAndCompile`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in pgvector" };
  }
}

export async function postExecFileAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/appstore/JAVAexecuteFile`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in pgvector" };
  }
}

export async function putJavaFileAPI(data) {
  try {
    const res = await axios.put(
      `${backend_url}/appstore/getFiles`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in putEditRole" };
  }
}

export async function getPlsqlProjectAPI() {
  try {
    const res = await axios.get(
      `${backend_url}/appstore/CreateGet`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in get Nodes and Edges" };
  }
}

export async function deletePlsqlProjectAPI(DATA) {
  try {
    const res = await axios.delete(`${backend_url}/appstore/CreateGet`, {
      data: DATA,
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in Delete Session DataDive" };
  }
}

export async function TestautomationAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/testautomation/testautomation`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in testautomation" };
  }
}

export async function generateSQLAPI(datasource_id, data) {
  try {
    const res = await axios.post(
      `${dataVizard}/generate-sql/${datasource_id}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in pgvector" };
  }
}

export async function generateTableAPI(datasource_id, data) {
  try {
    const res = await axios.post(
      `${dataVizard}/execute-sql/${datasource_id}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in pgvector" };
  }
}

export async function generateGraphAPI(datasource_id, data) {
  try {
    const res = await axios.post(
      `${dataVizard}/generate-visualization/${datasource_id}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in generateGraphAPI" };
  }
}

export async function getQuestionsAPI(questionID) {
  try {
    const res = await axios.get(
      `${dataVizard}/question/${questionID}`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in generateGraphAPI" };
  }
}

export async function alreadyQuestionAPI(id, data) {
  try {
    const res = await axios.put(
      `${dataVizard}/question/${id}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Question API" };
  }
}

export async function firstQuestionAPI(data) {
  try {
    const res = await axios.post(
      `${dataVizard}/question`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Question API" };
  }
}

export async function deleteQuestionAPI(DATA) {
  try {
    const res = await axios.delete(`${dataVizard}/question/${DATA}`, {
      data: DATA,
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in Delete Session DataDive" };
  }
}

export async function fileOrFolderUploadAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/embeddings/FileUploadView`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in fileOrFolderUploadAPI" };
  }
}
export async function Routeoptimizers(data) {
  try {
    const res = await axios.get(`${backend_url}/optimizer/send_requests`);
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in routeApi" };
  }
}

export async function postrouteoptiguideapi(data) {
  try {
    const res = await axios.post(
      `${backend_url}/optimizer/FileUploadView`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in fileOrFolderUploadAPI" };
  }
}

export async function PostRouteoptimizers(data) {
  try {
    const res = await axios.post(
      `${backend_url}/optimizer/send_requests`,
      data
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in routeApi" };
  }
}

export async function getTestRunsAPI(data) {
  try {
    const res = await axios.get(
      `${backend_url}/testautomation/session/get_session_ids`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in routeApi" };
  }
}

export async function PostsessionId(data) {
  try {
    const res = await axios.post(
      `${backend_url}/testautomation/session/session_details`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in PostsessionId" };
  }
}

export async function DeletesessionId(data) {
  try {
    const res = await axios.post(
      `${backend_url}/testautomation/session/delete`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in PostsessionId" };
  }
}

export async function getSessionImageChatAPI() {
  try {
    const res = await axios.get(
      `${backend_url}/Imagechat/get_imagechat_sessions`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Get Session DataDive" };
  }
}

export async function deleteSessionImagechatAPI(DATA) {
  try {
    const res = await axios.delete(
      `${backend_url}/Imagechat/get_imagechat_sessions`,
      {
        data: DATA,
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in Delete Session DataDive" };
  }
}

export async function createSessionImagchatAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/Imagechat/ImagesUploadCreateEmbeddings`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Get Session DataDive" };
  }
}
export async function getImageChatAPI(data) {
  try {
    const res = await axios.post(
      `${backend_url}/Imagechat/get_image_chat`,
      data,
      commonHeader()
    );
    // var i = 0
    // for(i; i < res.data['data'].length; i ++){

    // }

    // var arr = JSON.parse(res.data);
    // var a = [];
    // var i = 0;
    // for (i; i < res.data["data"].length; i++) {
    //   a.push(res.data["data"][i]["genai_bot"][0]);
    //   // console.log("serdfgwesrgdfewsr---", res.data['data'][i]['genai_bot'][0])
    // }

    // console.log("Hay darling i am here------", a);
    // console.log("fdkjbghfbivlkdfb..................",res.data);
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in getImageChat" };
  }
}
export async function chat_Image_API(data) {
  try {
    const res = await axios.post(
      `${backend_url}/Imagechat/Query_images_with_llm`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in query document with llm" };
  }
}

export async function UploadFile(data) {
  try {
    const res = await axios.post(
      `${backend_url}/speechtotext/FileUploadView`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in FileUploadView" };
  }
}

export async function CreateSession(data) {
  try {
    const res = await axios.post(
      `${backend_url}/speechtotext/createSessions`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in CreateSession" };
  }
}

export async function GetSessionsAudio() {
  try {
    const res = await axios.get(
      `${backend_url}/speechtotext/GetSessions`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Get Session DataDive" };
  }
}

export async function PostTranscription(data) {
  try {
    const res = await axios.post(
      `${backend_url}/speechtotext/postTranscription`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in CreateSession" };
  }
}

export async function RelevantChunksApi(data) {
  try {
    const res = await axios.post(
      `${backend_url}/embeddings/getReliventChunks`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in CreateSession" };
  }
}

// speechtotext....................................................//

export async function SpeechDeleteApi(data) {
  try {
    const res = await axios.delete(
      `${backend_url}/speechtotext/deleteSession`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in CreateSession" };
  }
}

export async function SpeechEditAPI(data) {
  try {
    const res = await axios.put(
      `${backend_url}/speechtotext/editSession`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in putEditRole" };
  }
}

export async function SpeechSummaryApi(data) {
  try {
    const res = await axios.post(
      `${backend_url}/speechtotext/postSummary`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in postSummary" };
  }
}

export async function SpeechSummaryputApi(data) {
  try {
    const res = await axios.put(
      `${backend_url}/speechtotext/editSUmmery`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in postSummary" };
  }
}

export async function Gettranscription(sessionid) {
  try {
    const res = await axios.get(
      `${backend_url}/speechtotext/getTranscription/${sessionid}`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Get Session DataDive" };
  }
}

export async function GetSummary(sessionid) {
  try {
    const res = await axios.get(
      `${backend_url}/speechtotext/getSummery/${sessionid}`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Get Session DataDive" };
  }
}

export async function DocQueryApi(sessionid, data) {
  try {
    const res = await axios.post(
      `${backend_url}/speechtotext/postDocQuery/${sessionid}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in postDocQuery" };
  }
}

export async function doEMbeddings(sessionid, data) {
  try {
    const res = await axios.post(
      `${backend_url}/speechtotext/doEMbeddings/${sessionid}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in postSummary" };
  }
}

export async function getDocQuery(sessionid) {
  try {
    const res = await axios.get(
      `${backend_url}/speechtotext/getDocQuery/${sessionid}`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Get Session DataDive" };
  }
}

// AIMedCode....................................................//

export async function getAISessions() {
  try {
    const res = await axios.get(
      `${backend_url}/AIMedCode/AIMedCode_sessions`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Get AIMedcode Session" };
  }
}

export async function PostAiSessions(data) {
  try {
    const res = await axios.post(
      `${backend_url}/AIMedCode/AIMedCode_sessions`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostAiSessions" };
  }
}

export async function deleteAISessionAPI(DATA) {
  try {
    const res = await axios.delete(
      `${backend_url}/AIMedCode/AIMedCode_sessions`,
      {
        data: DATA,
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in deleteAISessionAPI" };
  }
}

export async function getEachAISessions(data) {
  try {
    const res = await axios.post(
      `${backend_url}/AIMedCode/get_chat`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in getEachAISessions" };
  }
}

export async function postNewSessionData(data) {
  try {
    const res = await axios.post(
      `${backend_url}/AIMedCode/generate_icd_codes`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in postNewSessionData" };
  }
}

export async function postNewSessionDataPro(data) {
  try {
    const res = await axios.post(
      `${backend_url}/AIMedCode/generate_icd_codes_pro`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in postNewSessionDataPro" };
  }
}

export async function PostUplaodExcelApi(data) {
  try {
    const res = await axios.post(
      `${Optilogistics_url}/optimize`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostUplaodExcelApi" };
  }
}

export async function GetRuns() {
  try {
    const res = await axios.get(
      `${Optilogistics_url}/get_runs`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Get AIMedcode Session" };
  }
}

export async function EachRunApi(data) {
  try {
    const res = await axios.post(
      `${Optilogistics_url}/get_optimization`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostUplaodExcelApi" };
  }
}

export async function TrackRun(data) {
  try {
    const res = await axios.post(
      `${Optilogistics_url}/track`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostUplaodExcelApi" };
  }
}

export async function DeleteRun(DATA) {
  try {
    const res = await axios.delete(`${Optilogistics_url}/delete_runs`, {
      data: DATA,
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in deleteSessionAPI" };
  }
}

export async function PostEmail(data) {
  try {
    const res = await axios.post(
      `${Optilogistics_url}/email`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostEmail" };
  }
}

export async function PostRecord(data) {
  try {
    const res = await axios.post(
      `${backend_url}/speechtotext/record_audio`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostRecord" };
  }
}

// ("<-------------------Content Generation Api's------------------->");

export async function PostProject(data) {
  try {
    const res = await axios.post(
      `${Content_url}/content-generation/project`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostProject" };
  }
}

export async function GetProject() {
  try {
    const res = await axios.get(
      `${Content_url}/content-generation/get-projects`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in GetGetProject" };
  }
}

export async function PostTemplate(data, projectId) {
  try {
    const res = await axios.post(
      `${Content_url}/content-generation/template/${projectId}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostProject" };
  }
}

export async function GetTemplate(projectId) {
  try {
    const res = await axios.get(
      `${Content_url}/content-generation/get-templates/${projectId}`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in GetGetProject" };
  }
}

export async function ContentdeleteProject(projectId) {
  try {
    const res = await axios.delete(
      `${Content_url}/content-generation/delete-project/${projectId}`,
      {
        // data: DATA,
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in ContentdeleteProject" };
  }
}

export async function Contentdeletetemplate(projectId) {
  try {
    const res = await axios.delete(
      `
${Content_url}/content-generation/delete-template/${projectId}`,
      {
        // data: DATA,
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in ContentdeleteProject" };
  }
}

export async function GetTittle(TemplateID) {
  try {
    const res = await axios.get(
      `${Content_url2}/content-generation-title/get-stage-data/${TemplateID}`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in GetTittle" };
  }
}

export async function Generate_Tittle(data) {
  try {
    const res = await axios.post(
      `${Content_url2}/content-generation-title/generate-title`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostProject" };
  }
}

export async function Save_Tittle(data) {
  try {
    const res = await axios.put(
      `${Content_url2}/content-generation-title/save-title`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in saveConfluenceAPI" };
  }
}

export async function Generate_Outliners(data) {
  try {
    const res = await axios.post(
      `${Content_url2}/content-generation-title/generate-outlines`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostProject" };
  }
}

export async function Save_Outline(data, projectId) {
  try {
    const res = await axios.put(
      `${Content_url2}/content-generation-title/save-outlines/${projectId}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in saveConfluenceAPI" };
  }
}

// ${Content_url2}/content-generation-title/generate-key-points

export async function Generate_Keypoints(data, projectId) {
  try {
    const res = await axios.post(
      `${Content_url2}/content-generation-title/generate-key-points/${projectId}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostProject" };
  }
}

// export async function GetRoles(data) {
//   try {
//     const res = await axios.get(
//       `${Content_url2}/content-generation-title/get-role`,
//       data,
//       commonHeader()
//     );
//     return res.data;
//   } catch (e) {
//     console.log("error---", e);
//     return { sucess: false, message: "Error in GetTittle" };
//   }
// }

export async function GetRoles() {
  try {
    const res = await axios.get(
      `${Content_url2}/content-generation-title/get-role`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in GetGetProject" };
  }
}

export async function PostRoles(data) {
  try {
    const res = await axios.post(
      `${Content_url2}/content-generation-title/add-role`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostRoles" };
  }
}
export async function PostMagicQuery(data) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/magicprompt`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostMagicQuery" };
  }
}

export async function PostMagicImageQuery(data, templateId) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/image-generation/${templateId}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostMagicImageQuery" };
  }
}

export async function PostQuery(data) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/text-generation`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostQuery" };
  }
}

export async function GetImages() {
  try {
    const res = await axios.get(
      `${Content_url3}/content-generation-image/get-all-images`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in GetImages" };
  }
}

export async function GetContent(templateId) {
  try {
    const res = await axios.get(
      `${Content_url2}/content-generation-title/get-stage-data/${templateId}`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in GetContent" };
  }
}

export async function ReplaceAPI(data) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/editing`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostQuery" };
  }
}

export async function GetBlogRoles() {
  try {
    const res = await axios.get(
      `${Content_url3}/content-generation-image/roles`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in GetBlogRoles" };
  }
}

export async function SaveDoc(data, templateId) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/save-content/${templateId}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostQuery" };
  }
}

export async function postGmail(data) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/gmailpost`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in postGmail" };
  }
}
export async function Keypoints_Finish(data, projectId) {
  try {
    const res = await axios.post(
      `${Content_url2}/content-generation-title/save-document/${projectId}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostProject" };
  }
}

export async function Savefile(data) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/uploadfile`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in postGmail" };
  }
}

export async function PostMedium(data) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/mediumpost`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in postGmail" };
  }
}

export async function PostLinkeIn(data) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/linkedinpost`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostMedium" };
  }
}

export async function PostAddrole(data) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/create-roles`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostMedium" };
  }
}

export async function test_automation_projects() {
  try {
    const res = await axios.get(
      `${backend_url}/testautomation/test_automation_projects`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Get test_automation_projects" };
  }
}
export async function Post_test_automation_projects(data) {
  try {
    const res = await axios.post(
      `${backend_url}/testautomation/test_automation_projects`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostAiSessions" };
  }
}

export async function Delete_Project(DATA) {
  try {
    const res = await axios.delete(
      `${backend_url}/testautomation/test_automation_projects`,
      {
        data: DATA,
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in deleteActorRoleAPI" };
  }
}

export async function get_all_features(data) {
  try {
    const res = await axios.post(
      `${backend_url}/testautomation/get_all_features`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in get_all_features" };
  }
}

export async function generate_test_cases(data) {
  try {
    const res = await axios.post(
      `${backend_url}/testautomation/generate_test_cases`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in get_all_features" };
  }
}

export async function get_all_test_cases(data) {
  try {
    const res = await axios.post(
      `${backend_url}/testautomation/get_all_test_cases`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in get_all_test_cases" };
  }
}

export async function get_output(data) {
  try {
    const res = await axios.post(
      `${backend_url}/testautomation/execute_test_scripts`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in get_output" };
  }
}

export async function modify_test_case(data) {
  try {
    const res = await axios.put(
      `${backend_url}/testautomation/modify_test_case`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in modify_test_case" };
  }
}

export async function Delete_Testcase(DATA) {
  try {
    const res = await axios.delete(
      `${backend_url}/testautomation/modify_test_case`,
      {
        data: DATA,
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in Delete_Testcase" };
  }
}

export async function Create_test_case(data) {
  try {
    const res = await axios.post(
      `${backend_url}/testautomation/modify_test_case`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Create_test_case" };
  }
}

export async function generate_test_script(data) {
  try {
    const res = await axios.post(
      `${backend_url}/testautomation/generate_test_script`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in generate_test_script" };
  }
}

export async function save_test_script(data) {
  try {
    const res = await axios.put(
      `${backend_url}/testautomation/generate_test_script`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in save_test_script" };
  }
}

export async function Delete_Testscript(DATA) {
  try {
    const res = await axios.delete(
      `${backend_url}/testautomation/generate_test_script`,
      {
        data: DATA,
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in Delete_Testcase" };
  }
}

export async function audio_processing(data) {
  try {
    const res = await axios.post(
      `${backend_url}/AIMedCode/audio_processing`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in audio_processing" };
  }
}

export async function EditTruck(data) {
  try {
    const res = await axios.post(
      `${Optilogistics_url}/modify_deliveries`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostUplaodExcelApi" };
  }
}

export async function Delete_Delivery(DATA) {
  try {
    const res = await axios.delete(`${Optilogistics_url}/delete_deliveries`, {
      data: DATA,
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in Delete_Testcase" };
  }
}

export async function regenerate_outlines(data) {
  try {
    const res = await axios.post(
      `${Content_url2}/content-generation-title/regenerate-outlines
`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in regenerate_outlines" };
  }
}
export async function get_all_test_plans(data) {
  try {
    const res = await axios.post(
      `${backend_url}/testautomation/get_all_test_plans`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in get_all_test_plans" };
  }
}

export async function PostTeststrategy(data) {
  try {
    const res = await axios.post(
      `${backend_url}/testautomation/get_test_strategy`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in postNewSessionDataPro" };
  }
}

export async function generate_test_plans(data) {
  try {
    const res = await axios.post(
      `${backend_url}/testautomation/generate_test_plans`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in generate_test_plans" };
  }
}